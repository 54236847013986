.select__control {
    padding: 5px !important;
    border-radius: 8px !important;
}

.select__multi-value {
    font-size: 18px !important;
    background-color: white !important;
    border: 1px solid #ddd !important;
    border-radius: 5px !important;
    padding: 0 5px !important;
}

/* .select__multi-value__label {
    color: white !important;
} */

.select__multi-value__remove:hover {
    background: none !important;
}

.select__control:focus-within {
    box-shadow: none !important;
    border-color: #ccc !important;
}

.css-9gakcf-option {
    background-color: #ff5733 !important;
    /* Change to your desired color */
    color: white !important;
    /* Change text color */
}