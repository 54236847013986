:root {
    --primary-color: #021766;
    --primary-color-rgb: 2, 23, 102;
    --secondary-color: #D2AB67;
}

.rdrDefinedRangesWrapper {
    display: none !important;
}

.rdrDay .rdrDayToday {
    color: var(--primary-color);
}

.rdrDayToday .rdrDayNumber span:after {
    background: none !important;
}

.rdrCalendarWrapper {
    padding: 10px;
    border-radius: 10px;
}

.rdrDateRangePickerWrapper {
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
    background-color: #eee !important;
    border-right: 1px solid #ccc;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.rdrInRange~.rdrDayNumber span,
.rdrStartEdge~.rdrDayNumber span,
.rdrEndEdge~.rdrDayNumber span {
    color: black !important;
}

.rdrDateDisplayWrapper {
    background-color: white !important;
}

.rdrDateDisplayItemActive {
    border-color: black !important;
}

.rdrDateDisplayItemActive input {
    color: black !important;
}

.rdrNextPrevButton {
    background-color: white !important;
}

.rdrNextPrevButton:hover {
    background-color: #eee !important;
}

.rdrMonthPicker select {
    background-color: white !important;
}

.rdrYearPicker select {
    background-color: white !important;
}

.rdrInputRanges input {
    background-color: var(--primary-color) !important;
    /* Set the background color for inputs */
    color: #000;
    /* Optional: change the input text color */
}

/* input[type="text"],
input[type="number"],
input[type="date"],
select,
textarea {
    font-weight: 400;
    color: #667085;
} */

.ReactModal__Body--open {
    overflow: hidden;
    width: 100%;
}

.table_row {
    height: 72px;
    cursor: pointer;
}

.table_row:hover {
    background-color: rgba(var(--primary-color-rgb), 0.04);
    /* background-color: rgba(0, 0, 0, 0.05); */
    border-left: 1px solid var(--primary-color);
}

.actions {
    /* background-color: #D1AB66; */
    color: white;
}

.delete-action {
    background-color: white;
    border: 1px solid red;
    color: red;
}

.delete-action:hover {
    background-color: red;
    color: white;
}

.info-action {
    background-color: white;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
}

.info-action:hover {
    background-color: var(--primary-color);
    color: white;
}

.approve-action {
    background-color: white;
    border: 1px solid #00AB66;
    color: #00AB66;
}

.approve-action:hover {
    background-color: #00AB66;
    color: white;
}

.reject-action {
    background-color: white;
    border: 1px solid red;
    color: red;
}

.reject-action:hover {
    background-color: red;
    color: white;
}

.edit-action {
    background-color: white;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
}

.edit-action:hover {
    background-color: var(--primary-color);
    color: white;
}

.actions:hover {
    /* background-color: rgba(var(--primary-color-rgb), 0.6); */
    /* color: red; */
    /* color: var(--primary-color); */
    /* border: 1px solid black; */
}

.MuiMenu-paper {
    transition-duration: 0s !important;
}

select {
    background-color: white;
}

.report-date-range .rdrDateDisplayWrapper {
    display: none
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* For Firefox */
}

.form-checkbox {
    appearance: none; /* Remove default checkbox styling */
    width: 20px;
    height: 20px;
    border-radius: 50%; /* Makes it fully rounded */
    border: 1px solid var(--primary-color); /* Border color */
    position: relative;
    background-color: #fff;
    cursor: pointer;
  }
  
  .form-checkbox:checked {
    background-color: var(--primary-color); /* Background color when checked */
    border-color: var(--primary-color);
  }
  
  .form-checkbox:checked::before {
    content: '\2714'; /* Unicode checkmark character */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1px;
    font-size: 12px;
    color: white; /* Checkmark color */
  }
  
  .form-checkbox:focus {
    outline: none; /* Optional: Removes focus outline */
  }
  
  input, select {
    outline: none;
  }