.dice-container {
  transform: scale(0.6);
  perspective: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.dice {
  width: 50px;
  height: 50px;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 3s ease-out;
}

.rolling {
  animation: rollInfinite 2s linear infinite;
}

@keyframes rollInfinite {
  0% { transform: rotateX(0deg) rotateY(0deg) }
  100% { transform: rotateX(900deg) rotateY(900deg) }
}

.face {
  position: absolute;
  width: 50px;
  height: 50px;
  background: var(--primary-color);
  border: 1px solid white;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

.front  { transform: translateZ(25px); }
.back   { transform: rotateY(180deg) translateZ(25px); }
.left   { transform: rotateY(-90deg) translateZ(25px); }
.right  { transform: rotateY(90deg) translateZ(25px); }
.top    { transform: rotateX(90deg) translateZ(25px); }
.bottom { transform: rotateX(-90deg) translateZ(25px); }
